import React from 'react'
import { Link, graphql } from 'gatsby'
import MDXRenderer from 'gatsby-mdx/mdx-renderer'
import styled from 'styled-components'
import Bio from '../components/Bio'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import Title from '../components/Title'

const PostWrapper = styled.div`
  padding: 0 2em 3em var(--pad4);
  max-width: 40em;
`

const Frontmatter = styled.div`
  padding-top: 2em;
  padding-left: 4em;
  padding: 1em var(--pad4);
  border-bottom: solid 1px var(--offPrimeGray);
  margin-bottom: 1em;
  position: relative;
  h1 {
    margin: 0;
    color: var(--offPrimeGray);
  }
  p {
    margin: 0;
  }
  @media screen and (max-width: 30em) {
    padding-left: 3rem
  }
`

const PostsNav = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style-type: none;
  padding-left: 0;
  padding-top: 1em;
  border-top: solid 1px var(--offPrimeGray);
`

const BackButton = styled(Link)`
  position: absolute;
  display: flex;
  align-items: center;
  padding: 1rem;
  font-size: 1rem;
  background: var(--offBg);
  left: 1px;
  top: 0;
  color: var(--offPrimeGray);
  border: solid 1px currentColor;
  border-top-color: transparent;
  border-left-width: 0;
  bottom: -1px;
  cursor: pointer;
  text-decoration: none;
  vertical-align: center;
  &:before {
    display: inline-block;
    padding-right: 0.5em;
    line-height: 1;
  }
  &:hover {
    color: var(--primeColor);
    border-color: currentColor;
    background: transparent;
  }
`

const LinkAll = ({ to, postType, ...props }) =>
  postType === 'blog' ? (
    <Link to={to} {...props} />
  ) : (
    <a href={to} target="_blank" {...props} />
  )

const BlogContent = ({children}) => <MDXRenderer>{children}</MDXRenderer>

const EmbedWrapper = styled.div`
  position: relative;
    overflow: hidden;
    padding-top: 56.25%;
    height:0
`

const Embed = styled.iframe`
  top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    border: 0;
`

const EmbedContent = ({embedSrc, embedScriptSrc}) =>
<EmbedWrapper>
  <Embed src={embedSrc} frameborder="0" allowfullscreen></Embed>
  {embedScriptSrc && <script src={embedScriptSrc}></script>}
</EmbedWrapper>

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.mdx
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={post.frontmatter.title} description={post.excerpt} />
        {false && <Title category="Work">{post.frontmatter.title}</Title>}
        <Frontmatter>
          <BackButton to="/blog">←</BackButton>
          <h1>{post.frontmatter.title}</h1>
          <p>{post.frontmatter.date}</p>
        </Frontmatter>
        <PostWrapper>
          {post.frontmatter.subType === 'embed'
            ? <EmbedContent embedSrc={post.frontmatter.embedSrc} embedScriptSrc={post.frontmatter.embedScriptSrc} />
            : <BlogContent>{post.code.body}</BlogContent>
          }

          <PostsNav style={{}}>
            <li>
              {previous && (
                <LinkAll
                  postType={previous.frontmatter.postType}
                  to={previous.fields.postPath}
                  rel="prev"
                >
                  ← {previous.frontmatter.title}
                </LinkAll>
              )}
            </li>
            <li>
              {next && (
                <LinkAll
                  postType={next.frontmatter.postType}
                  to={next.fields.postPath}
                  rel="next"
                >
                  {next.frontmatter.title} →
                </LinkAll>
              )}
            </li>
          </PostsNav>
        </PostWrapper>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 120)
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        embedSrc
        subType
        embedScriptSrc
      }
      code {
        body
      }
    }
  }
`
